<script>
import { storeToRefs } from 'pinia'
import { useToast } from 'kbfmf-libraries'

export default defineComponent({
  setup() {
    // auth logic
    const authStore = useAuth()
    const loaderStore = useLoader()
    const isLoading = ref(false)

    const username = ref('')
    const password = ref('')
    const emailRef = ref('')

    const { resetPasswordSuccess } = storeToRefs(authStore)
    const showPassword = ref(false)
    const captchaImage = ref('')
    const captchaId = ref('')
    const captchaText = ref('')
    const isLoadingCaptcha = ref(false)
    const validateMessage = ref({
      username: '',
      password: '',
      email: '',
      captcha: '',
    })
    const alertSucces = ref({
      show: false,
      message: '',
    })
    const alertFailed = ref({
      show: false,
      message: '',
    })

    const toast = useToast()

    watchEffect(() => {
      isLoading.value = loaderStore.isLoading
    })

    const validateLogin = (field) => {
      if (field === 'username') {
        if (username.value === '')
          validateMessage.value.username = 'Username tidak boleh kosong'
        else
          validateMessage.value.username = ''
      }
      else {
        if (password.value === '')
          validateMessage.value.password = 'Password tidak boleh kosong'
        else
          validateMessage.value.password = ''
      }
    }

    const getUserBranch = async () => {
      return await authStore.getBranchByUserId(authStore.user?.id)
    }

    const handleSubmit = async () => {
      validateLogin('username')
      validateLogin('password')
      if ([username.value, password.value].includes(''))
        return false

      loaderStore.startLoading()
      alertSucces.value.message = ''

      const response = await authStore.login({
        username: username.value,
        password: password.value,
      })

      if (response.error) {
        setTimeout(() => {
          toast.error({ message: response.message })
          loaderStore.stopLoading()
        }, 500)
      }
      else {
        const userBranch = await getUserBranch()
        if (userBranch) {
          if (authStore.isMultiLob)
            return navigateTo('/select-lob')

          authStore.setActiveLob(authStore.activeLob)
          toast.success({ message: 'Login Berhasil!', timeout: 1000 })
          setTimeout(() => {
            return navigateTo('/')
          }, 1000)
        }
        else {
          toast.error({ message: 'Login Gagal.' })
          authStore.logout()
          navigateTo('/login')
        }
      }
    }
    // end of auth logic

    // forgot logic
    const validateForgot = () => {
      if (emailRef.value === '' || captchaText.value === '') {
        validateMessage.value.email = emailRef.value === '' ? 'Email tidak boleh kosong' : ''
        validateMessage.value.captcha = captchaText.value === '' ? 'Kode Captcha tidak boleh kosong' : ''
        return false
      }
      const checkPattern = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(emailRef.value)
      if (!checkPattern) {
        validateMessage.value.email = 'Format Email salah'
        return false
      }
      else {
        validateMessage.value.email = ''
        return true
      }
    }

    watch(() => emailRef.value, () => {
      if (emailRef.value !== '')
        validateMessage.value.email = ''
    })

    async function getCaptchaImage() {
      const response = await authStore.generateCaptcha()
      if (!response.error) {
        captchaId.value = response.data.captcha_id
        const url = `data:${response.data.content_type};base64, ${response.data.captcha_image}`
        useGetBlobUrl(url).then((blob) => {
          captchaImage.value = blob
        })
      }
    }

    const handleRefreshCaptcha = async () => {
      isLoadingCaptcha.value = true
      setTimeout(() => {
        getCaptchaImage()
        isLoadingCaptcha.value = false
      }, 500)
    }

    watch(
      () => captchaText.value,
      (text) => {
        if (text.length === 0)
          validateMessage.value.captcha = 'Kode Captcha tidak boleh kosong'
        else
          validateMessage.value.captcha = ''
      },
    )

    const handleForgot = async () => {
      if (!validateForgot())
        return false

      if (emailRef.value !== '') {
        loaderStore.startLoading()
        const payload = {
          email: emailRef.value,
          captcha_id: captchaId.value,
          captcha_text: captchaText.value,
        }
        const response = await authStore.forgotPassword(payload)

        setTimeout(() => {
          if (response.error) {
            if (response.status === 'Invalid')
              validateMessage.value.captcha = 'Kode Captcha Salah'
            toast.error({ message: response.message })
          }
          else {
            toast.success({ message: `Permintaan reset password berhasil dikirim. Silakan cek email Anda ${emailRef.value} dan ikuti petunjuk yang ada pada email tersebut.` })
          }
          handleRefreshCaptcha()
          loaderStore.stopLoading()
        }, 500)
      }
    }
    // end of forgot logic

    // page change logic
    const changePageRef = ref(1)

    const resetPage = () => {
      username.value = ''
      password.value = ''
      emailRef.value = ''
      isLoading.value = false
      alertSucces.value.show = false
      alertSucces.value.message = ''
      alertFailed.value.show = false
      alertFailed.value.message = ''
    }

    const handlePageChange = (page) => {
      resetPage()
      changePageRef.value = page
      if (page === 2)
        getCaptchaImage()
    }
    // end of page change logic

    onMounted(() => {
      definePageMeta({
        layout: null,
      })
    })

    return {
      username,
      password,
      handleSubmit,
      isLoading,
      changePageRef,
      handlePageChange,
      handleForgot,
      emailRef,
      resetPasswordSuccess,
      showPassword,
      validateMessage,
      validateLogin,
      alertSucces,
      alertFailed,
      validateForgot,
      captchaImage,
      captchaText,
      handleRefreshCaptcha,
      isLoadingCaptcha,
    }
  },
})
</script>

<template>
  <ClientOnly>
    <NuxtLayout name="guest">
      <template #title>
        {{ changePageRef === 1 ? 'Selamat Datang' : 'Lupa Password' }}
      </template>
      <div class="mb-5">
        <a
          v-if="changePageRef === 2"
          class="text-sm text-blue-500"
          href="#"
          @click.prevent="handlePageChange(1)"
        >Kembali ke Login?</a>
      </div>

      <BaseAlert v-if="resetPasswordSuccess" class="mb-5" color="bg-[#0C965A] text-white">
        <div><Icon name="mdi:check-circle" size="20" /></div>
        Reset Password berhasil. Silakan login menggunakan username dan Password baru Anda.
      </BaseAlert>

      <BaseAlert v-if="alertSucces.show" class="mb-5" color="bg-[#0C965A] text-white">
        <div><Icon name="mdi:check-circle" size="20" /></div>
        <div v-if="alertSucces.message.includes('Permintaan reset password berhasil dikirim')">
          Permintaan reset password berhasil dikirim. Silakan cek email Anda <strong class="font-bold">{{ emailRef }}</strong> dan ikuti petunjuk yang ada pada email tersebut.
        </div>
        <div v-else>
          {{ alertSucces.message }}
        </div>
      </BaseAlert>

      <BaseAlert v-if="alertFailed.show" class="mb-5" color="bg-[#D81600] text-white">
        <div><Icon name="mdi:alert" size="20" /></div>
        {{ alertFailed.message }}
      </BaseAlert>

      <!-- Login  -->
      <div v-if="changePageRef === 1" class="flex flex-col gap-5">
        <KInput
          v-model="username"
          label="Nama Pengguna"
          placeholder="Username"
          :error-message="validateMessage.username"
          @change="validateLogin('username')"
        />
        <KInput
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          placeholder="Password"
          :error-message="validateMessage.password"
          @keyup.enter="handleSubmit"
          @change="validateLogin('password')"
        >
          <template #append>
            <Icon
              class="cursor-pointer"
              :name="showPassword ? 'mdi:eye' : 'mdi:eye-off'"
              @click="showPassword = !showPassword"
            />
          </template>
        </KInput>
        <a class="text-sm text-blue-500" href="#" @click.prevent="handlePageChange(2)">Lupa password?</a>
        <KButton :disabled="isLoading" :loading="isLoading" @click="handleSubmit">
          Masuk
        </KButton>
      </div>

      <!-- Forgot Password  -->
      <div v-else class="flex flex-col gap-5">
        <BaseAlert color="bg-blue-50" custom-class="!items-start !mb-0">
          <IconsInfo />
          <div>Masukkan email yang telah didaftarkan untuk melakukan reset password.</div>
        </BaseAlert>
        <KInput
          v-model="emailRef"
          type="email"
          label="Email"
          placeholder="Email"
          :error-message="validateMessage.email"
          @keyup.enter="handleForgot"
          @change="validateForgot()"
        />
        <div class="flex justify-between gap-2 items-center">
          <div class="w-4/12 flex items-center">
            <div class="rounded-l-lg bg-gray-100">
              <img :src="captchaImage" alt="captcha image" class="">
            </div>
            <button
              class="bg-blue-100 px-2 py-5 rounded-r-lg"
              :class="{ 'cursor-not-allowed bg-gray-100': isLoadingCaptcha }"
              :disabled="isLoadingCaptcha"
              @click="handleRefreshCaptcha"
            >
              <Icon
                class="text-[#4782FC]"
                :class="{ 'text-gray-600': isLoadingCaptcha }"
                :name="!isLoadingCaptcha ? 'ic:baseline-refresh' : 'svg-spinners:180-ring'"
                size="20"
              />
            </button>
          </div>

          <div class="w-8/12">
            <KInput
              v-model="captchaText"
              placeholder="Masukkan Kode Captcha"
              :error-message="validateMessage.captcha"
            />
          </div>
        </div>
        <KButton :disabled="isLoading" :loading="isLoading" @click="handleForgot">
          Kirim
        </KButton>
      </div>
    </NuxtLayout>
  </ClientOnly>
</template>
